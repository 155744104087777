@import 'variables.css';
body.mobile {
  padding-top: var(--mobile-header-height);
}
body.without-header {
  padding-top: 0;
}
.mobile .content__wrapper,
.mobile-content__wrapper {
  max-width: var(--mobile-max-width);
  min-width: 300px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.mobile-content__wrapper {
  margin: auto;
}
.mobile main {
  animation: mobile-main 0.6s ease-out;
}
@keyframes mobile-main {
  from {
    opacity: 0.3;
    transform: translateY(7px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.mobile .header {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--mobile-header-height);
  background-color: var(--foreground-gray-color);
  -webkit-box-shadow: 0 0 0.3rem rgb(0 0 0 / 15%) !important;
  box-shadow: 0 0 0.3rem rgb(0 0 0 / 15%) !important;
}
.mobile .footer {
  background-color: var(--foreground-gray-color);
  margin-top: 10px;
}
.mobile .calendar-full-mount-title {
  padding: 0 !important;
}
.mobile .main__wrapper {
  margin-top: 25px;
  margin-bottom: 70px;
}
.mobile .hotelian-logo {
  width: 120px;
}
.navbar__wrapper {
  width: 100%;
  height: 80px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-transition: height 0.3s linear;
  -o-transition: height 0.3s linear;
  transition: height 0.3s linear;
}
.mobile .app-loading {
  top: var(--mobile-header-height);
}
.c-navbar__header {
  padding: 5px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.rtl .c-navbar__header {
  padding: 5px !important;
}
.mobile-menu-drawer {
  z-index: 990;
  position: fixed;
  top: var(--mobile-header-height);
  bottom: 0;
  left: 0;
  width: var(--mobile-drawer-width);
  background-color: #fff;
  transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transform: translateX(var(--mobile-drawer-width-n));
}
.rtl .mobile-menu-drawer {
  transform: translateX(var(--mobile-drawer-width));
  left: auto;
  right: 0;
}
.mobile-menu-drawer-content-wrapper {
  height: 100vh;
  overflow: auto;
  padding-bottom: 50px;
}
.mobile-intellisense-items-ctn {
  height: calc(100vh - var(--mobile-header-height));
  position: fixed;
  top: var(--mobile-header-height);
  background-color: #fff;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1010;
  overflow: auto;
  /* animation: mobile-intellisense-ctn 0.3s ease-out;
  -o-animation: mobile-intellisense-ctn 0.3s ease-out;
  -moz-animation: mobile-intellisense-ctn 0.3s ease-out;
  -webkit-animation: mobile-intellisense-ctn 0.3s ease-out; */
}
.mobile-intellisense-content-action-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--foreground-gray-color);
  /* animation: mobile-intellisense-ctn 0.3s ease-out;
  -o-animation: mobile-intellisense-ctn 0.3s ease-out;
  -moz-animation: mobile-intellisense-ctn 0.3s ease-out;
  -webkit-animation: mobile-intellisense-ctn 0.3s ease-out; */
  z-index: 1100;
}
.mobile-intellisense-content-wrapper {
  height: 100%;
  padding-bottom: 180px;
  overflow: auto;
}
@keyframes mobile-intellisense-ctn {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}
.mobile-nav-links {
  font-weight: 300;
  font-size: 12px;
}
.mobile .styles_react-code-input-container__tpiKG input {
  width: 35px !important;
  height: 35px !important;
}
.mobile .phone-number-input .phone-number-prefix-ctn {
  flex: 2 !important;
}
.mobile .phone-number-input .phone-number-number-input-ctn {
  flex: 5 !important;
}
.mobile .searchbox__container .sb__content__wrapper {
  width: 100%;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0;
}
.mobile .searchbox__container .sb__content__wrapper .sb__title__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.mobile .dyn__text {
  flex-grow: unset;
}
.footer-social-media-icon {
  width: 24px;
}
.mobile .main-searchbox-container input {
  background-color: transparent !important;
}
.mobile .searchbox__container .MuiFormLabel-root {
  width: 92% !important;
}
.mobile .rd__banner__wrapper {
  position: static;
}
.mobile .MuiBreadcrumbs-ol {
  flex-wrap: nowrap !important;
}
.mobile .MuiBreadcrumbs-li {
  white-space: nowrap !important;
}
/* .mobile .hotel-slider-number-pagination {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  z-index: 10;
  margin: 0 !important;
  padding: 5px 10px;
}
.rtl.mobile .hotel-slider-number-pagination {
  right: auto;
  left: 0;
} */
.mobile .hotel-slider {
  background-color: #2d2327;
}
.mobile .hotel-slider img {
  min-width: auto !important;
  max-width: 350px !important;
  width: calc(100% - 45px) !important;
}
.mobile .hotel-slider .swiper-button-prev,
.mobile .hotel-slider .swiper-button-next {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  width: 36px;
  height: 36px;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.mobile .availibility-list-ctn {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.mobile .mob-bg-color {
  background-color: #f7f8fc;
}
.mobile-fixed-hotel-action-bar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
}
.mobile .hotel-header-buttons {
  width: 100%;
  height: 60px;
  display: block;
}
.mobile .hotel-header-buttons img {
  width: 30px;
  height: auto;
}

.mobile .hotel-header-buttons:hover {
  background-color: #fff;
}
.mobile-fixed-hotel-action-bar-toggler {
  height: 20px;
}
.mobile-fixed-hotel-action-bar-toggler-btn {
  transform: translateY(5px);
  transition: all 0.3s 0.4s ease-out;
  -o-transition: all 0.3s 0.4s ease-out;
  -moz-transition: all 0.3s 0.4s ease-out;
  -webkit-transition: all 0.3s 0.4s ease-out;
}
.mobile-fixed-hotel-action-bar-toggler-btn-active {
  transform: translateY(4px) rotateX(180deg);
}
.mobile-fixed-hotel-action-bar-toggler::before {
  content: '';
  position: absolute;
  top: 4px;
  left: calc(50% - 32px);
  z-index: -1;
  width: 64px;
  height: 40px;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--theme);
  -webkit-box-shadow: 0 -1.5px 3px 0 rgb(0, 0, 0, 0.16);
  box-shadow: 0 -1.5px 3px 0 rgb(0, 0, 0, 0.16);
}
.mobile-fixed-hotel-action-bar-toggled {
  transform: translateY(70px);
}

.mobile .facilities-checklist {
  clear: both;
  -webkit-column-count: auto;
  -moz-column-count: auto;
  column-count: auto;
  -webkit-column-gap: auto;
  -moz-column-gap: auto;
  column-gap: auto;
}
.mobile .facilities-checklist .facilities-checklist-item {
  display: block;
  width: auto;
}
.mobile .facilities-item-list {
  clear: both;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
.mobile .top-city-slider-ctn {
  width: 120px !important;
  margin-bottom: 10px;
}
.mobile .top-city-slider-ctn button {
  padding: 5px 0 !important;
}
.mobile .top-city-slider-ctn button * {
  font-size: 0.9rem !important;
}
.mobile .top-cities-item .top-cities-item-absolute-collapse-ctn .top-cities-item-collapse-title {
  height: auto;
}
.mobile .top-cities-top-destinations-content {
  background-color: transparent;
  height: auto !important;
}
.mobile .top-cities-top-destinations {
  height: 150px;
}
.mobile .main-magazine-item {
  height: auto;
}
.mobile .main-magazine-item img {
  height: 190px !important;
}
.mobile-guests-ctn select {
  padding-top: 4px;
  padding-bottom: 4px;
  width: 113px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: var(--main-border-radius);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.mobile-guests-ctn .mobile-guests-select-wrapper {
  position: relative;
}
.mobile-guests-ctn .mobile-guests-select-wrapper::after {
  content: '';
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #555555;
}
.rtl .mobile-guests-ctn .mobile-guests-select-wrapper::after {
  left: 5px;
  right: auto;
}
.mobile .breadcrumb__wrapper {
  max-width: var(--mobile-max-width);
  margin: auto;
}
.mobile .breadcrumb__wrapper .breadcrumb__cnt {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}
.mobile .breadcrumb__wrapper .breadcrumb__cnt ol li:last-child p {
  margin-right: 15px;
}
.rtl.mobile .breadcrumb__wrapper .breadcrumb__cnt {
  padding-right: 15px;
  padding-left: 0;
}
.rtl.mobile .breadcrumb__wrapper .breadcrumb__cnt ol li:last-child p {
  margin-left: 15px;
  margin-right: 0;
}
.mobile .search-hotel-item .search-hotel-item-tax {
  padding: 0;
  border: none;
  color: var(--theme-secondary);
  text-align: start;
}
.mobile .search-hotel-item .search-hotel-item-acc-summary {
  padding: 0;
  border: none;
  text-align: start;
}
.mobile .search-hotel-item-acc-summary {
  font-weight: bold;
}
.close-mobile-map-ctn {
  position: absolute;
  top: 10px;
  z-index: 10;
  right: 10px;
}
.filter-mobile-map-ctn {
  position: fixed;
  bottom: 20px;
  z-index: 10;
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
}
button.rounded-btn {
  border-radius: 200px;
}
.mobile-map-hotel-detail-popup {
  position: fixed;
  bottom: 20px;
  right: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 12;
  border-radius: var(--main-border-radius);
  min-height: 182px;
  animation: slide-up 0.5s ease-out;
}
.mobile-map-hotel-detail-popup.mobile-map-hotel-detail-popup-without-btn {
  min-height: 90px;
}
.mobile-map-hotel-detail-popup img {
  background-color: rgba(0, 0, 0, 0.12);
}
.mobile-map-hotel-detail-popup .mobile-map-hotel-detail-popup-close-btn-ctn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 14;
}
.rtl .mobile-map-hotel-detail-popup .mobile-map-hotel-detail-popup-close-btn-ctn {
  left: 0;
  right: auto;
}
.mobile-map-hotel-detail-popup-spinner-ctn {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes slide-up {
  0% {
    transform: translateY(130%);
  }
  100% {
    transform: translateY(0);
  }
}
@supports not (backdrop-filter: blur(30px)) {
  .mobile-map-hotel-detail-popup {
    background-color: #fff;
  }
}
.mobile-hotel-images-ctn > div {
  height: 100px;
}
.mobile-hotel-images-ctn > div:first-child {
  height: 150px;
}
.mobile .contact-us-map-wrapper {
  height: 220px;
  margin: auto;
  max-width: var(--mobile-max-width);
}
.mobile .top-hotel-cnt {
  width: 260px !important;
}
.mobile .top-hotel-item {
  height: 200px;
}
.mobile-rooms-ctn {
  overflow-y: hidden;
  overflow-x: auto;
}
.mobile .room-changer-section-title {
  width: 160px;
}
.mobile .hotel-rooms-item {
  padding: 5px 0;
}
.mobile .hotel-rooms-item::after,
.mobile .hotel-rooms-item::before {
  width: auto;
  left: 0;
  right: 0;
  height: 5px;
}
.mobile .hotel-rooms-item::after {
  bottom: 0;
  top: auto;
}
.mobile .hotel-rooms-item::before {
  top: 0;
  bottom: auto;
}
@media (max-width: 360px) {
  .mobile .room-changer-section-title {
    width: 120px;
  }
  .mobile .mobile-hotel-searcher-btn-ctn-acc-summary {
    font-size: 80% !important;
    font-weight: 400 !important;
  }
}
.mobile .confirm-reserve-modal-arrow-ctn > div {
  transform: rotate(90deg);
}
.mobile-customer-panel-item {
  border: 1px solid #c5c5c5;
  border-radius: var(--main-border-radius);
}
.mobile-customer-panel-item .mobile-customer-panel-item-border-bottom {
  border-bottom: 1px solid #c5c5c5;
}
.mobile-customer-panel-item .mobile-customer-panel-item-border-top {
  border-top: 1px solid #c5c5c5;
}
.mobile .my-bookings-detail-room-passengers .my-bookings-detail-room-passengers-text-ctn {
  width: 100px;
}
.mobile .error-ctn {
  background-image: url(./images/404-error-BG.png);
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: bottom left;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile .error-ctn > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mobile .error-ctn > div > .error-image-ctn > img {
  width: 200px;
  height: auto;
}
.mobile .error-ctn .error-ctn-title {
  font-weight: 900;
  font-size: 25px;
  margin-bottom: 5px;
  margin-top: 8px;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
}
.mobile .error-ctn .error-ctn-description {
  text-align: center;
}
.mobile .error-ctn .error-ctn-go-to-home-button {
  margin-top: 10px;
}
.mobile-bottom-fixed-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 950;
  -webkit-box-shadow: 0 0 0.3rem rgb(0 0 0 / 15%) !important;
  box-shadow: 0 0 0.3rem rgb(0 0 0 / 15%) !important;
}
.mobile .magazine-header-image-ctn {
  height: 250px;
}
.mobile .mobile-magazine-categories-wrapper {
  margin-top: -125px;
  overflow-y: hidden;
  z-index: 1;
}
.mobile .mobile-magazine-categories-wrapper > div {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.mobile .magazine-search-ctn input {
  padding: 12px 12px;
  font-size: 1rem;
}
.mobile .magazine-category-slider-item {
  background-color: #4b4e55;
  border-radius: var(--main-border-radius);
  height: 80px;
}
.mobile .magazine-category-slider-item-active {
  background-color: #e0e0e0 !important;
  font-weight: bold;
}
.mobile .magazine-category-slider-item > a > span {
  padding: 5px;
  color: #fff !important;
}
.mobile .magazine-category-slider-item-active > a > span {
  color: #4b4e55 !important;
}
.mobile .recent-articles-item {
  border: 1px solid #c5c5c5;
  border-radius: var(--main-border-radius);
}
.mobile .recent-articles-item img {
  height: 150px;
}
.mobile .hotel-type-badge {
  transform: translateY(-1px);
}
.mobile .search-loading-wrapper {
  background-size: contain;
}
.mobile .searchbox__container {
  background-position: top center;
}
.mobile .page__banner--extend {
  height: 500px;
}
@media (min-width: 440px) {
  .mobile #google-signin-btn iframe {
    width: 430px !important;
  }
  .mobile #google-signin-btn {
    display: flex;
    justify-content: center;
  }
}
.mobile .main-faq-ctn {
  overflow-x: scroll;
}
.mobile .main-faq-ctn li {
  min-width: 270px;
  max-width: 270px;
  width: 270px;
}
.mobile .main-about-us-description {
  text-align: justify;
}
.main-magazine-big-item .main-magazine-big-item-image-ctn {
  height: 260px;
}
.mobile .main-magazine-sidebar {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.mobile .main-magazine-sidebar > article {
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  border: 1px solid #eeeeee;
  border-radius: var(--main-border-radius);
  padding: 10px;
}
.mobile .main-magazine-sidebar > article:not(.mobile .main-magazine-sidebar > article:last-child) {
  margin-right: 15px;
}
.rtl.mobile .main-magazine-sidebar > article:not(.rtl.mobile .main-magazine-sidebar > article:last-child) {
  margin-left: 15px;
}
.rtl.mobile .main-magazine-sidebar > article {
  margin-right: 0;
}
.mobile .header-with-search-ctn {
  padding: 20px 0;
}
.mobile .states-top-cities-ctn {
  overflow-x: scroll;
}
.mobile .states-top-cities-ctn > ul > li {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}
.mobile .mobile-phone-number-picker .MuiAutocomplete-popper {
  flex-grow: 1;
  overflow-y: scroll;
}
.mobile .mobile-phone-number-picker .MuiAutocomplete-popper .MuiAutocomplete-listbox {
  max-height: none;
  height: 100%;
}
.mobile .region-top-hotels-wrapper {
  overflow-x: auto;
  padding-bottom: 12px;
}
.mobile .region-top-hotels-wrapper li {
  width: 270px;
  max-width: 270px;
  min-width: 270px;
  margin-bottom: 5px;
}
.mobile .reserve-leader-information-ctn {
  border: none;
}
