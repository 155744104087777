body.mobile {
  background-color: #f8f8f8 !important;
}
.mobile .b2b-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: var(--mobile-header-height);
  z-index: 1100;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../public/images/app-header-bg.jpg);
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);
}
.mobile .b2b-header-height {
  height: var(--mobile-header-height);
}
.mobile .b2b-header > div {
  position: relative;
  height: 100%;
}
.mobile .b2b-header > div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-transparent-x);
}
.mobile .b2b-header > div > div {
  z-index: 5;
}
.b2b-app-mobile-drawer {
  width: 250px;
  height: 100%;
  background-color: var(--theme);
}
.b2b-app-mobile-drawer .b2b-app-mobile-drawer-user-detail {
  padding: 30px 15px;
  background-color: #032c91;
}
.b2b-app-mobile-drawer .b2b-app-mobile-drawer-general-detail {
  padding: 30px 15px;
  background-color: #032c91;
  display: flex;
  justify-content: center;
}
.b2b-app-mobile-drawer .b2b-app-mobile-drawer-menu-wrapper {
  padding: 30px 15px;
}
.mobile .b2b-recent-searches-list-ctn {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 8px;
}
.mobile .b2b-content-container {
  padding-top: 15px;
}
.mobile .b2b-search-main-loading-ctn > div {
  padding-top: 120px;
  padding-bottom: 120px;
}
.mobile .b2b-book-loading-cmp {
  padding: 12px;
  min-width: 250px;
}


.mobile .mobileFilterButtons {
  position:fixed !important;
  bottom:0;
  width:100%;
  background-color:white;
  padding:15px 31px;
  display:flex;
  justify-content:center;
  left:0;
  z-index:10
}

